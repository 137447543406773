import React, { useMemo } from 'react';
// components
import Loader from 'components/Loader';
import NoDataBar from 'components/NoDataBar';
// routers
import { useParams, Link, useHistory } from 'react-router-dom';
import { AuthRoutes, CommonRoutes } from 'navigation/Routes';
// localization
import { useTranslation } from 'react-i18next';
// redux
import useTSelector from 'hooks/redux/useTSelector';
// utils
import { format } from 'date-fns';
// hooks
import { useQuery } from 'react-query';
// services
import api from 'services/api';
// images
import cakeIcon from 'assets/icons/cake.svg';
import noUserIcon from 'assets/icons/comment-account-outline.svg';
import userIcon from 'assets/icons/user.svg';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const ProfileHeader: React.FC = () => {
  const { t } = useTranslation();
  const myProfile = useTSelector((state) => state.auth.user);
  const history = useHistory();

  const { pathname } = history.location;

  const pageLastPathname = useMemo(() => `/${pathname.split('/')[pathname.split('/').length - 1]}`, [pathname]);

  // TODO: implement ts for navigation
  const { userId } = useParams<{ userId?: string; wishlistId?: string; giftId?: string }>();

  const { data: user, isLoading } = useQuery(['user', userId], (_key: string, id: number) => api.user.get(id));

  const { firstName, lastName, photo, followersCount, followingCount, birthDate } = user || {};

  const fullName = useMemo(() => `${firstName} ${lastName}`, [firstName, lastName]);

  return (
    <div className={cn('profile')}>
      {isLoading ? (
        <Loader />
      ) : user ? (
        <>
          <div className={cn('profile-avatar')}>
            {photo?.path ? (
              <img src={photo.path} alt='' className={cn('profile-avatar__image')} />
            ) : (
              <img src={userIcon} alt='' className={cn('profile-avatar__without-image')} />
            )}
          </div>
          <p className={cn('profile-fullname')}>{fullName}</p>

          <div className={cn('profile-info')}>
            <div className={cn('profile-info__follow-wrapper')}>
              <Link
                to={myProfile ? `/user/${userId}/followers` : AuthRoutes.SignIn}
                className={cn('profile-info__follow', CommonRoutes.followers === pageLastPathname && 'active')}
              >
                <span className={cn('profile-info__count')}>{followersCount}</span> {t('follow:followers')}
              </Link>

              <Link
                to={myProfile ? `/user/${userId}/following` : AuthRoutes.SignIn}
                className={cn('profile-info__follow', CommonRoutes.following === pageLastPathname && 'active')}
              >
                <span className={cn('profile-info__count')}>{followingCount}</span> {t('follow:following')}
              </Link>
            </div>

            {birthDate && (
              <p className={cn('profile-info__birthday')}>
                <span className={cn('profile-info__birthday-icon')}>
                  <img src={cakeIcon} alt='cake' />
                </span>

                {user.isHiddenBirthDate
                  ? format(new Date(birthDate), 'MMMM dd')
                  : format(new Date(birthDate), 'MMMM dd, yyyy')}
              </p>
            )}
          </div>
        </>
      ) : (
        <NoDataBar icon={noUserIcon} description={t('common:noUserFound')} />
      )}
    </div>
  );
};

export default ProfileHeader;
