import React, { useCallback } from 'react';
// components
import Button from 'components/Button';
// routes
import { useHistory } from 'react-router-dom';
// hooks
import { queryCache } from 'react-query';
// services
import api from 'services/api';
import firebase from 'firebase/app';
import { updateAuthData as updateAuthDataInStorage } from 'services/storage/auth';
// redux
import useTDispatch from 'hooks/redux/useTDispatch';
import { updateAuth } from 'store/modules/auth';
// types
import { SocialType } from '../../../../entities/dictionaries';
// icons
import twitterIcon from 'assets/icons/twitter-circle.svg';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
// firebase products
import 'firebase/auth';

const cn = classNames.bind(styles);

const Twitter: React.FC = () => {
  const dispatch = useTDispatch();
  const history = useHistory();

  const onSignIn = useCallback(async () => {
    try {
      const twitterProvider = new firebase.auth.TwitterAuthProvider();
      const authResponse = await firebase.auth().signInWithPopup(twitterProvider);
      // fix error: Property 'accessToken' does not exist on type 'AuthCredential'.ts(2339)
      const authCredential = authResponse.credential as firebase.auth.OAuthCredential;

      if (authCredential.accessToken && authCredential.secret) {
        const authData = await api.auth.socialSignIn({
          tokens: {
            token1: authCredential.accessToken,
            token2: authCredential.secret,
          },
          socialType: SocialType.Twitter,
          platformName: 'web',
        });

        updateAuthDataInStorage(authData);

        const user = await api.auth.getProfile();

        if (user) {
          dispatch(updateAuth(user));
          queryCache.clear();
          history.push(`/user/${user.id}`);
        } else {
          throw new Error('Something went wrong with getting user');
        }
      } else {
        throw new Error('Something went wrong obtaining the users access token');
      }
    } catch (error) {
      // TODO: improve error flow
      console.error(error);
    }
  }, [history, dispatch]);

  return (
    <Button mode='text' onClick={onSignIn} className={cn('social-button')}>
      <img src={twitterIcon} alt='Twitter' />
    </Button>
  );
};

export default Twitter;
