import React, { Fragment } from 'react';
// routes
import { Link } from 'react-router-dom';
// utils
import { substring } from 'utils/substring';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

export type BreadcrumbType = { title?: string; link?: string };

type Props = {
  data: BreadcrumbType[];
};

const cn = classNames.bind(styles);

const LIMIT = 20;

const Breadcrumb: React.FC<Props> = ({ data }) => {
  return (
    <div className={cn('breadcrumb')}>
      {data.map(({ link, title }, index) => (
        <Fragment key={index}>
          {title && (
            <>
              <Link to={{ pathname: link }} className={cn('breadcrumb-link', !link && 'breadcrumb-unlink')}>
                {substring(title ?? '', LIMIT)}
              </Link>
              <span className={cn('breadcrumb-chevron')} />
            </>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
