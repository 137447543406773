// types
import { AuthSession } from 'entities/auth';
// utils
import Cookies from 'js-cookie';

const AUTH_DATA_KEY = 'authData';

const getAuthData = async (): Promise<AuthSession['token'] | undefined> => {
  try {
    const authData = await Cookies.get(AUTH_DATA_KEY);
    return authData;
  } catch (error) {
    console.log('AsyncStorage auth get error:', error.message);
  }
};

const updateAuthData = async (data: AuthSession): Promise<void> => {
  try {
    await Cookies.set(AUTH_DATA_KEY, `${data.token}`);
  } catch (error) {
    console.log('AsyncStorage auth set error:', error.message);
  }
};

const clearAuthData = async (): Promise<void> => {
  return await Cookies.remove(AUTH_DATA_KEY);
};

export { updateAuthData, getAuthData, clearAuthData };
