import React, { useMemo } from 'react';
// components
import { Formik } from 'formik';
import Input from 'components/Form/Input';
import Button from 'components/Button';
// localization
import { useTranslation } from 'react-i18next';
// types
import { ForgotPasswordFormValuesType, ForgotPasswordFormOnSubmit } from './types';
// utils
import getValidationSchema from './validation';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

export type Props = {
  initialValues: ForgotPasswordFormValuesType;
  onSubmit: ForgotPasswordFormOnSubmit;
};

const ForgotPasswordForm: React.FC<Props> = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation();
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <>
          <Input name='email' label={t('auth:email')} type='email' />

          <Button type='submit' onClick={() => handleSubmit()} className={cn('form-button')}>
            {t('auth:resetPassword')}
          </Button>
        </>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
