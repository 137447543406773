import React from 'react';
// components
import Apple from './Apple';
import Google from './Google';
import Twitter from './Twitter';
import Facebook from './Facebook';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const SocialSignIn: React.FC = () => {
  return (
    <div className={cn('social-buttons')}>
      <Facebook />
      <Google />
      <Twitter />
      <Apple />
    </div>
  );
};

export default SocialSignIn;
