// localization
import { TFunction } from 'i18next';
// utils
import * as Yup from 'yup';

const getValidationSchema = (t: TFunction) =>
  Yup.object({
    email: Yup.string().email(t('forms:email')).required(t('forms:required')),
  }).defined();

export default getValidationSchema;
