import React from 'react';
// components
import Button from 'components/Button';
// entitites
import { Gift } from 'entities/gift';
// localization
import { useTranslation } from 'react-i18next';
// utils
import { displayOrderCurrency } from 'utils/currency';
import { substring } from 'utils/substring';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

type Props = React.HTMLProps<HTMLDivElement> & {
  gift: Gift;
  onPhotoClick?: () => void;
};

const cn = classNames.bind(styles);

const LIMIT = 30;

const GiftCard: React.FC<Props> = ({
  className,
  onPhotoClick,
  gift: { name, photos, price, currency, affiliateLink },
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn('gift-card', className)}>
      <div
        className={cn('gift-card__photo')}
        style={{ background: `center / cover no-repeat url(${photos?.[0]?.path})` }}
        onClick={onPhotoClick}
      />

      <p className={cn('gift-card__name')}>{substring(name, LIMIT)}</p>
      <p className={cn('gift-card__price')}>{displayOrderCurrency(price, currency)}</p>
      <a href={affiliateLink} rel='noopener noreferrer' target='_blank'>
        <Button className={cn('gift-card__button')}>{t('common:shop')}</Button>
      </a>
    </div>
  );
};

export default GiftCard;
