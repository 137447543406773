import React, { useCallback } from 'react';
// localization
import { useTranslation } from 'react-i18next';
// routes
import { Link } from 'react-router-dom';
import { AuthRoutes, CommonRoutes } from 'navigation/Routes';
// hooks
import { queryCache } from 'react-query';
// redux
import useTSelector from 'hooks/redux/useTSelector';
import useTDispatch from 'hooks/redux/useTDispatch';
// actions
import { logOut } from 'store/modules/auth/effects';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

type Props = {
  isSmall?: boolean;
};
const Header: React.FC<Props> = ({ isSmall }) => {
  const user = useTSelector((state) => state.auth.user);
  const dispatch = useTDispatch();

  const { t } = useTranslation();

  const handleLogOut = useCallback(async () => {
    await dispatch(logOut());
    queryCache.clear();
  }, [dispatch]);

  return (
    <header className={cn('container', isSmall && 'small')}>
      <div className={cn('wrapper')}>
        <Link to={user ? `${CommonRoutes.user}/${user.id}` : '/'} className={cn('logo')}>
          The giftbuster
        </Link>

        {user ? (
          <Link to={AuthRoutes.SignIn} className={cn('logo')} onClick={handleLogOut}>
            {t('auth:logout')}
          </Link>
        ) : (
          <Link to={AuthRoutes.SignIn} className={cn('logo')}>
            {t('auth:signIn')}
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
