import React, { useEffect } from 'react';
// pages
import ListsTab from './Tabs/ListsTab';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const UserPage: React.FC = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div className={cn('user')}>
      <ListsTab />
    </div>
  );
};

export default UserPage;
