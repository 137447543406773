export enum StaticRoutes {
  FAQ = '/faq',
  Terms = '/terms',
  Privacy = '/privacy',
}

export enum AuthRoutes {
  SignIn = '/sign-in',
  EmailConfirmed = '/email-confirmed/:hash?',
  PasswordChange = '/password-change/:hash?',
  ForgotPassword = '/forgot-password',
}

export const CommonRoutes = {
  user: '/user',
  wishList: '/wishlist',
  gift: '/gift',
  followers: '/followers',
  following: '/following',
};

export const ProfileRoutes = {
  User: `${CommonRoutes.user}/:userId(\\d+)`,
  WishList: `${CommonRoutes.user}/:userId(\\d+)${CommonRoutes.wishList}/:wishlistId(\\d+)`,
  Gift: `${CommonRoutes.user}/:userId(\\d+)${CommonRoutes.wishList}/:wishlistId(\\d+)${CommonRoutes.gift}/:giftId(\\d+)`,
  Followers: `${CommonRoutes.user}/:userId(\\d+)${CommonRoutes.followers}`,
  Following: `${CommonRoutes.user}/:userId(\\d+)${CommonRoutes.following}`,
};
