import React, { useCallback, useEffect, useState } from 'react';
// router
import { RouteComponentProps } from 'react-router-dom';
// localization
import { useTranslation } from 'react-i18next';
// services
import api from 'services/api';
// styles
import styles from './EmailConfirmed.module.scss';

type EmailConfirmedParams = {
  hash: string;
};

type EmailConfirmedProps = RouteComponentProps<EmailConfirmedParams>;

const EmailConfirmed: React.FC<EmailConfirmedProps> = ({ match, history }) => {
  const [isConfirmed, setConfirmed] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { t } = useTranslation();

  const confirmEmail = useCallback(async () => {
    if (match.params.hash) {
      setLoading(true);
      try {
        await api.auth.confirmEmail({ hash: match.params.hash });
        setConfirmed(true);
        history.replace('/email-confirmed');
      } catch (error) {
        console.error(error);
        setConfirmed(false);
      } finally {
        setLoading(false);
      }
    }
  }, [match, history]);

  useEffect(() => {
    confirmEmail();
  }, [confirmEmail]);

  return (
    <>
      {isLoading ? (
        <div className={styles.block}>{t('auth:confirm:confirmation')}</div>
      ) : (
        isConfirmed && (
          <div className={styles.block}>
            <p className={styles.title}>{t('auth:thank')}</p>
            <p>{t('auth:confirm:confirmed')}</p>
            <p>{t('auth:confirm:please')}</p>
          </div>
        )
      )}
    </>
  );
};

export default React.memo(EmailConfirmed);
