export default {
  common: {
    home: 'Home',
    faq: 'FAQ',
    seeTerms: 'Terms & Conditions',
    privacy: 'Privacy policy',
    rights: 'All Rights Reserved',
    shop: 'Shop',
    noDataFound: 'No data',
    noDataFoundDescription: 'No data to show found on this page',
    noUserFound: 'User not found',
    search: 'Search',
    contact: 'Contact Us',
    workWithUs: 'Work with Us',
  },
  forms: {
    required: 'Required field',
    email: 'Incorrect email',
    number: 'Incorrect value. Must be a number',
    positive: 'Incorrect value. Must be a positive number',
    integer: 'Incorrect value. Must be an integer number',
    minNumberValue: 'Must be greater or equal to {{min}}',
    maxNumberValue: 'Must be less or equal to {{max}}',
  },
  follow: {
    followers: 'Followers',
    following: 'Following',
  },
  auth: {
    thank: 'Thank you,',
    email: 'E-mail',
    password: 'Password',
    resetPassword: 'Reset Password',
    forgottenPassword: 'Forgotten Password?',
    login: 'Login',
    logout: 'Log Out',
    signIn: 'Sign In',
    confirm: {
      confirmation: 'Confirmation processing...',
      confirmed: 'Your email is confirmed.',
      please: 'Please enjoy your journey in the Application.',
    },
    change: {
      changed: 'Your password is changed.',
      change: 'Change password',
      newPassword: ' New password',
      confirm: 'Confirm new password',
      processing: 'Processing...',
    },
  },
  landing: {
    allWishes: 'One App For All Your Wishes',
    downloadApp: 'Download App for Free Now',
    download: 'Download App',
    forFree: 'for Free Now',
    account: 'Don’t have an account?',
    stepOne: {
      title: '1. Create ONE or MULTI wishlists.',
      description: 'One for yourself, your kids, your spouse. And one for the things you want to buy later.',
    },
    stepTwo: {
      title: '2. Add anything from ANY STORE.',
      description: 'Save all links and product information with just one click, without leaving the browser.',
    },
    stepThree: {
      title: '3. Share your wishlist INSTANTLY.',
      description: 'Let your family, friends, and co-workers know what you want.',
    },
    stepFour: {
      title: '4. Receive promo codes, price drops and deal alerts from the brands you love',
      description: 'Get the discount codes that work and the sales-best offer that interests you.',
    },
    getStart: {
      titleOne: 'Giftbuster your shopping wishlist',
      descriptionOne: 'Download For FREE',
    },
    blog: {
      title: 'BLOG & NEWS',
      description: 'Follow the latest shopping trends',
      readMore: 'Read More Blogs',
    },
    collaborate: {
      title: "Let's collaborate",
      label: 'Make your brand stand out',
      description:
        'Promote your brand amongst savvy online shoppers on the Giftbuster platform. Amplify your brand awareness and customer acquisition!',
    },
  },
  birthdays: {
    birthdayToday: 'Birthday Today',
  },
};
