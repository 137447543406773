import axios from '../axios';
// entities
import {
  AuthEmailConfirmRequestData,
  AuthPasswordChangeRequestData,
  AuthEmailSigninRequestData,
  AuthForgotPasswordRequestData,
  AuthSession,
  AuthUser,
  AuthSocialSigninRequestData,
} from 'entities/auth';

export default {
  confirmEmail: (data: AuthEmailConfirmRequestData): Promise<void> => axios.post('/auth/confirm/email', data),
  changePassword: (data: AuthPasswordChangeRequestData): Promise<void> => axios.post('/auth/reset/password', data),
  signIn: (data: AuthEmailSigninRequestData): Promise<AuthSession> => axios.post('/auth/login/email', data),
  forgotPassword: (data: AuthForgotPasswordRequestData): Promise<void> => axios.post('/auth/forgot/password', data),
  getProfile: (): Promise<AuthUser> => axios.get('/auth/me'),
  socialSignIn: (data: AuthSocialSigninRequestData): Promise<AuthSession> => axios.post('auth/login/social', data),
};
