import React, { useState } from 'react';
// icons
import openEyeIcon from 'assets/icons/eye-outline.svg';
import closeEyeIcon from 'assets/icons/eye-off-outline.svg';
// styles
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cn = classNames.bind(styles);

export type InputComponentProps = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: string;
  label?: string;
  isPassword?: boolean;
  labelStyle?: string;
};

const InputComponent: React.FC<InputComponentProps> = ({ error, isPassword, labelStyle, label, ...props }) => {
  const [isOpenEye, setIsOpenEye] = useState(false);

  return (
    <div className={cn('input')}>
      <label htmlFor={props.name} className={cn('input-label', labelStyle)}>
        {label}
      </label>

      <div className={cn('input-field__wrapper', error && 'input-field__wrapper-error')}>
        <input
          className={cn('input-field')}
          {...props}
          type={isPassword ? (isOpenEye ? 'text' : 'password') : props.type}
        />

        {isPassword && (
          <span onClick={() => setIsOpenEye(!isOpenEye)} className={cn('input-icon')}>
            <img src={isOpenEye ? openEyeIcon : closeEyeIcon} alt='' />
          </span>
        )}
      </div>
      {error && <span className={cn('input-error')}>{error}</span>}
    </div>
  );
};

export default InputComponent;
