import React from 'react';
// components
import StoreButton from 'components/StoreButton';
import Steps from 'components/Landing/Steps';
import GetStart from 'components/Landing/GetStart';
import Footer from 'components/Footer';
import Blog from 'components/Landing/Blog';
import Collaborate from 'components/Landing/Collaborate';
import YouTube from 'components/Landing/YouTube';
// routes
import { Link } from 'react-router-dom';
import { AuthRoutes } from 'navigation/Routes';
// localization
import { useTranslation } from 'react-i18next';
// styles
import styles from './styles.module.scss';

const LandingPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.bgImageTop}>
            <div className={styles.topContent}>
              <div className={styles.contentContainer}>
                <div className={styles.header}>
                  <span className={styles.giftbuster}>The Giftbuster</span>
                  <Link to={AuthRoutes.SignIn} className={styles.giftbuster}>
                    {t('auth:signIn')}
                  </Link>
                </div>

                <span className={styles.heading}>{t('landing:allWishes')}</span>
                <div className={styles.buttonContainer}>
                  <StoreButton store='google' />
                  <StoreButton store='apple' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Steps />
      <YouTube />
      <Collaborate />
      <Blog />
      <GetStart />
      <Footer />
    </div>
  );
};

export default LandingPage;
