import React, { useState, useMemo, useEffect } from 'react';
// components
import Button from 'components/Button';
import Loader from 'components/Loader';
import Breadcrumb, { BreadcrumbType } from 'components/Breadcrumb';
import NoDataBar from 'components/NoDataBar';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
// localization
import { useTranslation } from 'react-i18next';
// routers
import { useParams } from 'react-router-dom';
import { CommonRoutes } from 'navigation/Routes';
// hooks
import { useQuery } from 'react-query';
// services
import api from 'services/api';
// icons
import leftArrowIcon from 'assets/icons/left-arrow.svg';
// utils
import { displayOrderCurrency } from 'utils/currency';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import 'pure-react-carousel/dist/react-carousel.es.css';

const cn = classNames.bind(styles);

const GiftPage: React.FC = () => {
  const { t } = useTranslation();

  // TODO: implement ts for navigation
  const { giftId, wishlistId, userId } = useParams<{ userId?: string; wishlistId?: string; giftId?: string }>();

  const { data: gift, isLoading } = useQuery(['gift', giftId], (_key: string, id: number) => api.gift.get(id));

  const { name, currency, price, description, affiliateLink, photos } = gift || {};

  const [selectedPhoto, setSelectedPhoto] = useState(photos?.[0]);

  const settings = useMemo(
    () => ({
      naturalSlideHeight: 79,
      naturalSlideWidth: 79,
      visibleSlides: 4,
      totalSlides: photos?.length || 0,
    }),
    [photos],
  );

  const breadcrumbs: BreadcrumbType[] = useMemo(() => {
    const userLink = `${CommonRoutes.user}/${gift?.user?.id || userId}`;
    const wishlistLink = `${userLink}${CommonRoutes.wishList}/${gift?.wishlist?.id || wishlistId}`;

    return [
      { title: gift?.user?.id || userId ? 'Wish Lists' : undefined, link: userLink },
      { title: gift?.wishlist?.name, link: wishlistLink },
      { title: gift?.name },
    ];
  }, [gift, userId, wishlistId]);

  useEffect(() => {
    setSelectedPhoto(gift?.photos[0]);
    window.scrollTo(0, 0);
  }, [gift]);

  return (
    <div className={cn('gift-wrapper')}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Breadcrumb data={breadcrumbs} />

          {gift ? (
            <div className={cn('gift')}>
              <div className={cn('gift-photos')}>
                <div
                  className={cn('gift-photos__selected')}
                  style={{ background: `center / cover no-repeat url(${selectedPhoto?.path})` }}
                />
                {photos && (
                  <CarouselProvider {...settings} className={cn('carousel')}>
                    {photos?.length > 4 && (
                      <>
                        <ButtonBack className={cn('carousel-button', 'carousel-button-left')}>
                          <img src={leftArrowIcon} alt='arrow-left' />
                        </ButtonBack>
                        <ButtonNext className={cn('carousel-button', 'carousel-button-right')}>
                          <img src={leftArrowIcon} alt='arrow-right' />
                        </ButtonNext>
                      </>
                    )}

                    <Slider>
                      {photos.map((photo, index) => (
                        <Slide index={index} key={photo.id} onClick={() => setSelectedPhoto(photo)}>
                          <div
                            className={cn('carousel-photo')}
                            style={{ background: `center / cover no-repeat url(${photo.path})` }}
                          />
                        </Slide>
                      ))}
                    </Slider>
                  </CarouselProvider>
                )}
              </div>

              <div className={cn('gift-content')}>
                <div className={cn('gift-info')}>
                  <p className={cn('gift-info__name')}>{name}</p>
                  <p className={cn('gift-info__price')}>{displayOrderCurrency(price, currency)}</p>
                  <p className={cn('gift-info__description')}>{description}</p>
                </div>

                <a href={affiliateLink} rel='noopener noreferrer' target='_blank'>
                  <Button className={cn('gift-button')}>{t('common:shop')}</Button>
                </a>
              </div>
            </div>
          ) : (
            <NoDataBar />
          )}
        </>
      )}
    </div>
  );
};

export default GiftPage;
