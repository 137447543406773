import React from 'react';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  mode?: 'outlined' | 'text';
  isLoading?: boolean;
};

const Button: React.FC<Props> = ({ className, mode, isLoading, children, ...rest }) => {
  return (
    <button className={cn('button', mode, className)} {...rest} disabled={isLoading}>
      {isLoading && <span className={cn('button-loader')} />}
      {children}
    </button>
  );
};

export default Button;
