// redux
import { createAsyncThunk } from '@reduxjs/toolkit';
import { sliceKey } from '.';
// actions
import { clearAuth } from '.';
// services
import firebase from 'firebase/app';
import { clearAuthData } from 'services/storage/auth';
// firebase products
import 'firebase/auth';

// thunk`s
export const logOut = createAsyncThunk(`${sliceKey}/logOut`, async (_, { dispatch }) => {
  window.FB.logout();
  firebase.auth().signOut();
  // clear auth data and redux store
  clearAuthData();
  dispatch(clearAuth());
});
