import React, { useMemo, useCallback } from 'react';
// components
import Form from './Form/index';
import FormWrapper from '../components/FormWrapper';
// services
import api from 'services/api';
// types
import { ForgotPasswordFormValuesType, ForgotPasswordFormOnSubmit } from './Form/types';

const ForgotPassword: React.FC = () => {
  const initialValues = useMemo(
    (): ForgotPasswordFormValuesType => ({
      email: '',
    }),
    [],
  );

  const onSubmit: ForgotPasswordFormOnSubmit = useCallback(async (values, { setErrors, setSubmitting }) => {
    try {
      await api.auth.forgotPassword(values);
    } catch ({ data }) {
      if (data.errors) {
        setErrors(data.errors);
      }
    } finally {
      setSubmitting(false);
    }
  }, []);

  return (
    <FormWrapper>
      <Form initialValues={initialValues} onSubmit={onSubmit} />
    </FormWrapper>
  );
};

export default ForgotPassword;
