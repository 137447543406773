import React from 'react';
// styles
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cn = classNames.bind(styles);

const Loader: React.FC = () => {
  return (
    <div className={cn('loader')}>
      <span className={cn('circle')} />
    </div>
  );
};

export default Loader;
