import axios, { AxiosRequestConfig } from 'axios';
// services
import { getAuthData } from 'services/storage/auth';
// utils
import get from 'lodash/get';

export const handlerEnabled = (config: AxiosRequestConfig): boolean => {
  return get(config, 'params.handlerEnabled', true);
};

// create axios instance
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
});

/* request interceptor */
axiosInstance.interceptors.request.use(
  async (config): Promise<AxiosRequestConfig> => {
    if (handlerEnabled(config)) {
      const authToken = await getAuthData();

      config.headers.authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

/* response interceptor */
axiosInstance.interceptors.response.use(
  ({ data }) => data,
  (responseData = {}) => {
    const { response, message } = responseData;
    const data = response.data;
    const status = response.status;

    return Promise.reject({
      data,
      message,
      status,
    });
  },
);

export default axiosInstance;
