import React, { useState, useMemo, useCallback } from 'react';
// components
import Search from 'components/Search';
import Loader from 'components/Loader';
import Follower from 'components/Follower';
import NoDataBar from 'components/NoDataBar';
import InfiniteScroll from 'react-infinite-scroller';
// routes
import { useParams, useHistory } from 'react-router-dom';
import { AuthRoutes } from 'navigation/Routes';
// entities
import { Follower as FollowerEntity } from 'entities/follow';
// redux
import useTSelector from 'hooks/redux/useTSelector';
// hooks
import useDebounce from 'hooks/functions/useDebounce';
import { useInfiniteQuery } from 'react-query';
// services
import api from 'services/api';
// assets
import { RequestQueryBuilder } from '@nestjsx/crud-request';
// styles
import styles from '../styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const Followers: React.FC = () => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  const myProfile = useTSelector((state) => state.auth.user);

  const history = useHistory();
  // TODO: implement ts for navigation
  const { userId } = useParams<{ userId?: string; wishlistId?: string; giftId?: string }>();

  const handleUserClick = useCallback(
    (id: number) => {
      history.push(`/user/${id}`);
    },
    [history],
  );

  const { data: paginatedFeeds, isLoading, fetchMore, canFetchMore } = useInfiniteQuery(
    [`followers/${userId}`, { debouncedSearch }],
    (_key: string, { debouncedSearch: searchParams }, page: number | undefined = 1) =>
      api.follow.getList(
        RequestQueryBuilder.create({
          limit: 21,
          page,
          join: ['follower.photo'],
          search: searchParams
            ? {
                $and: [
                  {
                    '"Follower"."userId"': userId,
                    $or: [
                      {
                        'follower.lastName': {
                          $contL: searchParams,
                        },
                      },
                      {
                        'follower.firstName': {
                          $contL: searchParams,
                        },
                      },
                    ],
                  },
                ],
              }
            : {
                $and: [{ '"Follower"."userId"': userId }],
              },
        }).query(),
      ),
    {
      getFetchMore: (lastGroup) => (lastGroup.page < lastGroup.pageCount ? lastGroup.page + 1 : undefined),
      // 5 min
      staleTime: 1000 * 60 * 5,
    },
  );

  const followers = useMemo(
    () =>
      paginatedFeeds?.reduce((res, page) => [...res, ...page.data], [] as FollowerEntity[]).map((el) => el.follower),
    [paginatedFeeds],
  );

  if (!myProfile) {
    history.push(AuthRoutes.SignIn);
  }

  return (
    <div className={cn('follow')}>
      <Search value={search} onChange={(e) => setSearch(e.currentTarget.value)} className={cn('search')} />

      {isLoading ? (
        <Loader />
      ) : followers && (followers?.length ?? 0) > 0 ? (
        <InfiniteScroll
          loadMore={() => canFetchMore && fetchMore()}
          hasMore={canFetchMore}
          loader={<Loader />}
          className={cn('follow-list')}
        >
          {followers.map((user) => (
            <Follower user={user} key={user.id} onClick={() => handleUserClick(user.id)} />
          ))}
        </InfiniteScroll>
      ) : (
        <NoDataBar />
      )}
    </div>
  );
};

export default Followers;
