import React from 'react';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const MainWrapper: React.FC = ({ children }) => <main className={cn('main-wrapper')}>{children}</main>;

export default MainWrapper;
