import React from 'react';
// components
import Header from 'components/Header';
import Footer from 'components/Footer';
// routers
import { Switch, Route } from 'react-router-dom';
import { StaticRoutes } from 'navigation/Routes';
// pages
const Terms = React.lazy(() => import('../../pages/Terms'));
const Privacy = React.lazy(() => import('../../pages/Pravicy'));
const FAQ = React.lazy(() => import('../../pages/FAQ'));

const Profile: React.FC = () => {
  return (
    <>
      <Header isSmall />

      <Switch>
        <Route path={StaticRoutes.Privacy} component={Privacy} />
        <Route path={StaticRoutes.Terms} component={Terms} />
        <Route path={StaticRoutes.FAQ} component={FAQ} />
      </Switch>

      <Footer />
    </>
  );
};

export default Profile;
