// redux
import { combineReducers } from '@reduxjs/toolkit';
// actions
import { logOut } from './modules/auth/effects';
// reducers
import auth from './modules/auth';

const appReducer = combineReducers({
  auth,
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const rootReducer = (state, action) => {
  if (action.type === logOut.fulfilled.toString()) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
