import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// root page
import App from 'navigation';
// service worker
import * as serviceWorker from './serviceWorker';
// services
import firebase from 'firebase/app';
// styles
import './index.css';

const firebaseConfig = {
  apiKey: 'AIzaSyAHgIMARpd1FnMVTzskfvTf5hDMCRazl4s',
  authDomain: 'giftbuster-3aae8.firebaseapp.com',
  databaseURL: 'https://giftbuster-3aae8.firebaseio.com',
  projectId: 'giftbuster-3aae8',
  storageBucket: 'giftbuster-3aae8.appspot.com',
  messagingSenderId: '685282362646',
  appId: '1:685282362646:web:36282be8dbb0b6a72e3c37',
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
