import React from 'react';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

export enum StepSide {
  left = 'left',
  right = 'right',
}

type StepProps = {
  title: string;
  description: string;
  image: string;
  imageSide?: StepSide;
  imageStyle?: string;
};

const Step: React.FC<StepProps> = ({ title, description, image, imageSide = StepSide.right, imageStyle }) => {
  return (
    <div className={cn('step', imageSide)}>
      <div className={cn('step-content')}>
        <p className={cn('step-title')}>{title}</p>
        <p className={cn('step-description')}>{description}</p>
      </div>

      <div className={cn('step-image', imageStyle)}>
        <img src={image} alt='' />
      </div>
    </div>
  );
};

export default Step;
