// redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// types
import { AuthUser } from 'entities/auth';

export const sliceKey = 'auth';

type AuthSliceState = {
  user: AuthUser | null;
};

// slice
const authSlice = createSlice({
  name: sliceKey,
  initialState: {
    user: null,
  } as AuthSliceState,
  reducers: {
    updateAuth(state, { payload }: PayloadAction<AuthUser>) {
      state.user = payload;
    },
    clearAuth(state) {
      state.user = null;
    },
  },
});

// actions
export const { updateAuth, clearAuth } = authSlice.actions;

// reducer
export default authSlice.reducer;
