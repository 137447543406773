import React, { useMemo } from 'react';
// entities
import { Wishlist } from 'entities/wishlist';
import { Gift } from 'entities/gift';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

type Props = React.HTMLProps<HTMLDivElement> & {
  wishlist: Wishlist;
  onClick: () => void;
};

const WishlistCard: React.FC<Props> = ({ wishlist: { lastGifts, name }, onClick, className }) => {
  const photos = useMemo(() => lastGifts?.map((gift: Gift) => gift.photos?.[0]).filter((value) => value), [lastGifts]);

  const otherPhotos = useMemo(() => photos?.slice(1, 3), [photos]);

  return (
    <div className={cn('wishlsit-card', className)} onClick={onClick}>
      <div className={cn('wishlsit-card__info')}>
        <p className={cn('wishlsit-card__name')}>{name}</p>
      </div>

      <div className={cn('wishlsit-card__photos')}>
        <span
          style={{ flex: 1, background: `center / cover no-repeat url(${photos?.[0]?.path})` }}
          className={cn('wishlsit-card__photo')}
        />

        {Boolean(otherPhotos?.length) && (
          <div className={cn('other-photos')}>
            {otherPhotos?.map((photo) => (
              <span
                key={photo.id}
                className={cn('wishlsit-card__photo')}
                style={{
                  height: `${100 / otherPhotos.length}%`,
                  background: `center / cover no-repeat url(${photo.path})`,
                }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default WishlistCard;
