import React, { FunctionComponent, memo } from 'react';
import cx from 'classnames';
// utils
import { playMarket, appStore } from 'utils/links';
// images
import appStoreImage from 'assets/images/app_store.svg';
import googleStoreImage from 'assets/images/google_store.svg';
// styles
import styles from './StoreButton.module.scss';

interface IStoreButton {
  store: 'google' | 'apple';
}

const StoreButton: FunctionComponent<IStoreButton> = ({ store }) => {
  return (
    <>
      {store === 'google' ? (
        <a href={playMarket} rel='noopener noreferrer' target='_blank' className={cx(styles.button, styles.google)}>
          <img src={googleStoreImage} alt='Google play' />
        </a>
      ) : (
        <a href={appStore} rel='noopener noreferrer' target='_blank' className={cx(styles.button, styles.apple)}>
          <img src={appStoreImage} alt='App store' />
        </a>
      )}
    </>
  );
};

export default memo(StoreButton);
