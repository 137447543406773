import React, { useCallback, useEffect, useState } from 'react';
// router
import { RouteComponentProps } from 'react-router-dom';
// localization
import { useTranslation } from 'react-i18next';
// hooks
import { useFormik } from 'formik';
// services
import api from 'services/api';
// utils
import * as Yup from 'yup';
// styles
import styles from './PasswordChange.module.scss';

type EmailConfirmedParams = {
  hash: string;
};

type PasswordChangeProps = RouteComponentProps<EmailConfirmedParams>;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Password is too short (6 chars min)')
    .max(64, 'Password is too long (64 chars max)')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], "Passwords don't match")
    .required('Password is required'),
});

// TODO: make refactor component after starting development web version
const PasswordChange: React.FC<PasswordChangeProps> = ({ match, history }) => {
  const { t } = useTranslation();

  const [isSuccessful, setSuccessful] = useState(false);

  const handleChangePassword = useCallback(
    async (values, { setErrors, setSubmitting }) => {
      try {
        await api.auth.changePassword({ hash: match.params.hash, password: values.password });

        setSuccessful(true);
        history.replace('/password-change');
      } catch ({ data }) {
        setSuccessful(false);

        if (data.errors) {
          setErrors(data.errors);
        } else {
          console.error(data);
        }
      } finally {
        setSubmitting(false);
      }
    },
    [match, history],
  );

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: handleChangePassword,
    validationSchema,
  });

  useEffect(() => {
    if (!isSuccessful && !match.params.hash) {
      history.push('/');
    }
  }, [history, match, isSuccessful]);

  return (
    <div className={styles.block}>
      {isSuccessful ? (
        <>
          <p className={styles.title}>{t('auth:thank')}</p>
          <p>{t('auth:change:changed')}</p>
        </>
      ) : (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <label className={styles.label}>
            {t('auth:change:newPassword')}
            <input
              name='password'
              type='password'
              onChange={formik.handleChange}
              value={formik.values.password}
              className={styles.input}
            />
            {formik.touched.password && formik.errors.password ? (
              <p className={styles.error}>{formik.errors.password}</p>
            ) : null}
          </label>
          <label className={styles.label}>
            {t('auth:change:confirm')}
            <input
              name='confirmPassword'
              type='password'
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              className={styles.input}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <p className={styles.error}>{formik.errors.confirmPassword}</p>
            ) : null}
          </label>

          <button type='submit' className={styles.button} disabled={formik.isSubmitting}>
            {formik.isSubmitting ? t('auth:change:processing') : t('auth:change:change')}
          </button>
        </form>
      )}
    </div>
  );
};

export default React.memo(PasswordChange);
