import React from 'react';
// components
import Step, { StepSide } from './components/Step';
// localization
import { useTranslation } from 'react-i18next';
// images
import imageOne from 'assets/images/steps/stepOne.png';
import imageTwo from 'assets/images/steps/stepTwo.png';
import imageThree from 'assets/images/steps/stepThree.png';
import imageFour from 'assets/images/steps/stepFour.png';
// styles
import styles from './styles.module.scss';

const Steps = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.steps}>
        <Step title={t('landing:stepOne:title')} description={t('landing:stepOne:description')} image={imageOne} />
        <Step
          title={t('landing:stepTwo:title')}
          description={t('landing:stepTwo:description')}
          image={imageTwo}
          imageSide={StepSide.left}
        />
        <Step
          title={t('landing:stepThree:title')}
          description={t('landing:stepThree:description')}
          image={imageThree}
        />
        <Step
          title={t('landing:stepFour:title')}
          description={t('landing:stepFour:description')}
          image={imageFour}
          imageSide={StepSide.left}
        />
      </div>
    </div>
  );
};

export default Steps;
