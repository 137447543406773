import React, { useMemo } from 'react';
// components
import TimeAgo from 'components/TimeAgo';
// localization
import { useTranslation } from 'react-i18next';
// entities
import { User } from 'entities/user';
// utils
import { format, getMonth, getYear, getDate, isBefore, isToday } from 'date-fns';
// icons
import cakeIcon from 'assets/icons/cake-fill-grey.svg';
import cakeFillIcon from 'assets/icons/cake-fill-orange.svg';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

type Props = {
  user: User;
  onClick?: () => void;
};

const cn = classNames.bind(styles);

const Follower: React.FC<Props> = ({ user, onClick }) => {
  const { t } = useTranslation();

  const { birthDate: birthDateString, isHiddenBirthDate, firstName, lastName } = user;

  const birthDate = useMemo(() => !!birthDateString && new Date(birthDateString), [birthDateString]);

  const thisYearBirthDate = useMemo(
    () => !!birthDate && new Date(getYear(new Date()), getMonth(birthDate), getDate(birthDate)),
    [birthDate],
  );

  const nextBirthDate = useMemo(
    () =>
      !!thisYearBirthDate && !!birthDate && isBefore(thisYearBirthDate, new Date())
        ? new Date(getYear(new Date()) + 1, getMonth(birthDate), getDate(birthDate))
        : thisYearBirthDate,
    [birthDate, thisYearBirthDate],
  );
  return (
    <div className={cn('follower')} onClick={onClick}>
      <span>
        <img src={user.photo?.path} alt='' className={cn('follower-photo')} />
      </span>

      <div className={cn('follower-info')}>
        <p className={cn('follower-name')}>{`${firstName} ${lastName}`}</p>

        {!!thisYearBirthDate && !!birthDate && (
          <div className={cn('follower-birthday')}>
            <div className={cn('follower-birthday-wrapper')}>
              {isToday(thisYearBirthDate) ? <img src={cakeFillIcon} alt='' /> : <img src={cakeIcon} alt='' />}

              <span className={cn('follower-birthday-day', isToday(thisYearBirthDate) && 'today')}>
                {birthDate &&
                  (isHiddenBirthDate
                    ? format(new Date(birthDate), 'MMMM dd')
                    : format(new Date(birthDate), 'MMMM dd, yyyy'))}
              </span>
            </div>

            <div className={cn('follower-birthday-ago')}>
              {!!thisYearBirthDate &&
                !!nextBirthDate &&
                (isToday(thisYearBirthDate) ? (
                  <span className={cn('follower-birthday-today')}>{t('birthdays:birthdayToday')}</span>
                ) : (
                  <TimeAgo dateTime={`${nextBirthDate}`} />
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Follower;
