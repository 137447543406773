import React, { useCallback } from 'react';
// components
import Button from 'components/Button';
// hooks
import { queryCache } from 'react-query';
// routes
import { useHistory } from 'react-router-dom';
// services
import api from 'services/api';
import { updateAuthData as updateAuthDataInStorage } from 'services/storage/auth';
// redux
import useTDispatch from 'hooks/redux/useTDispatch';
import { updateAuth } from 'store/modules/auth';
// types
import { SocialType } from '../../../../entities/dictionaries';
// icons
import googleIcon from 'assets/icons/google-circle.svg';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const Google: React.FC = () => {
  const dispatch = useTDispatch();
  const history = useHistory();

  const onSignIn = useCallback(async () => {
    try {
      window.gapi.load('auth2', async () => {
        const auth2 = window.gapi.auth2.init({
          client_id: '685282362646-5qalcuofh4cfk2aoh8ndt9nn6gl2vog9.apps.googleusercontent.com',
        });
        const googleUser = await auth2.signIn();
        const authResponse = googleUser.getAuthResponse();

        if (authResponse) {
          const authData = await api.auth.socialSignIn({
            tokens: {
              token2: authResponse.id_token,
            },
            socialType: SocialType.Google,
            platformName: 'web',
          });

          updateAuthDataInStorage(authData);

          const user = await api.auth.getProfile();

          if (user) {
            dispatch(updateAuth(user));
            queryCache.clear();
            history.push(`/user/${user.id}`);
          } else {
            throw new Error('Something went wrong with getting user');
          }
        } else {
          throw new Error('Something went wrong obtaining the users access token');
        }
      });
    } catch (error) {
      // TODO: improve error flow
      console.error(error);
    }
  }, [history, dispatch]);

  return (
    <Button mode='text' onClick={onSignIn} className={cn('social-button')}>
      <img src={googleIcon} alt='Google' />
    </Button>
  );
};

export default Google;
