import React from 'react';
// components
import ProfileHeader from 'components/ProfileHeader';
import MainWrapper from 'components/MainWrapper';
import Header from 'components/Header';
import Footer from 'components/Footer';
// pages
import WishlistPage from 'pages/Wishlist';
import UserPage from 'pages/User';
import GiftPage from 'pages/Gift';
import Followers from 'pages/Follow/Followers';
import Following from 'pages/Follow/Following';
// routers
import { Switch, Route, Redirect } from 'react-router-dom';
import { ProfileRoutes } from 'navigation/Routes';

const Profile: React.FC = () => {
  return (
    <>
      <MainWrapper>
        <Header />
        <ProfileHeader />

        <Switch>
          <Route exact path={ProfileRoutes.Gift} component={GiftPage} />
          <Route exact path={ProfileRoutes.WishList} component={WishlistPage} />
          <Route exact path={ProfileRoutes.User} component={UserPage} />

          <Route exact path={ProfileRoutes.Followers} component={Followers} />
          <Route exact path={ProfileRoutes.Following} component={Following} />

          <Redirect to='/' />
        </Switch>
      </MainWrapper>

      <Footer />
    </>
  );
};

export default Profile;
