export type ItemDescription = {
  id: number;
  code: string;
};

export type Country = ItemDescription & {
  name: string;
};

export type Currency = ItemDescription & {
  sign: string;
};

export enum SocialType {
  Facebook = 'facebook',
  Google = 'google',
  Twitter = 'twitter',
  Apple = 'apple',
}

export type Platform = {
  id: number;
  name?: string;
};

export type Language = ItemDescription;
