import React, { FunctionComponent, memo, Suspense } from 'react';
// navigation
import AppNavigation from 'navigation/App';
// redux
import { Provider as StoreProvider } from 'react-redux';
import { store } from 'store';
// localization
import { I18nextProvider } from 'react-i18next';
import i18n from 'services/localization/i18n';

const App: FunctionComponent = () => (
  <>
    <Suspense fallback='loading'>
      <StoreProvider store={store}>
        <I18nextProvider i18n={i18n}>
          <AppNavigation />
        </I18nextProvider>
      </StoreProvider>
    </Suspense>
  </>
);

export default memo(App);
