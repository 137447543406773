import React, { useMemo } from 'react';
// components
import { Formik } from 'formik';
import Input from 'components/Form/Input';
import Button from 'components/Button';
// routes
import { Link } from 'react-router-dom';
import { AuthRoutes } from 'navigation/Routes';
// localization
import { useTranslation } from 'react-i18next';
// types
import { SignInFormValuesType, SignInFormOnSubmit } from 'pages/Auth/SingIn/components/Form/types';
// utils
import getValidationSchema from 'pages/Auth/SingIn/components/Form/validation';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

export type SignInFormProps = {
  initialValues: SignInFormValuesType;
  onSubmit: SignInFormOnSubmit;
};

const SignInForm: React.FC<SignInFormProps> = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting }) => (
        <>
          <Input name='email' label={t('auth:email')} type='email' labelStyle={cn('form-label')} />

          <Input name='password' label={t('auth:password')} isPassword labelStyle={cn('form-label')} />

          <p className={cn('form-forgot-password')}>
            <Link to={AuthRoutes.ForgotPassword}>{t('auth:forgottenPassword')}</Link>
          </p>

          <Button type='submit' onClick={() => handleSubmit()} className={cn('form-button')} isLoading={isSubmitting}>
            {t('auth:login')}
          </Button>
        </>
      )}
    </Formik>
  );
};

export default SignInForm;
