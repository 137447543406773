import React from 'react';
// localization
import { useTranslation } from 'react-i18next';
// icon
import magnifyIcon from 'assets/icons/magnify.svg';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

type Props = React.HTMLProps<HTMLInputElement>;

const Search: React.FC<Props> = ({ className, ...props }) => {
  const { t } = useTranslation();

  return (
    <div className={cn('search', className)}>
      <img src={magnifyIcon} alt='' className={cn('search-icon')} />
      <input className={cn('search-field')} placeholder={t('common:search')} {...props} />
    </div>
  );
};

export default Search;
