import React from 'react';
// components
import AuthWrapper from 'pages/Auth/components/AuthWrapper';
import Footer from 'components/Footer';
import Header from 'pages/Auth/components/Header';
// pages
import EmailConfirmed from 'pages/Auth/EmailConfirmed';
import PasswordChange from 'pages/Auth/PasswordChange';
import SingIn from 'pages/Auth/SingIn';
import ForgotPassword from 'pages/Auth/ForgotPassword';
// routers
import { Switch, Route, Redirect } from 'react-router-dom';
import { AuthRoutes } from 'navigation/Routes';

const Auth: React.FC = () => {
  return (
    <AuthWrapper>
      <Header />

      <Switch>
        <Route exact path={AuthRoutes.SignIn} component={SingIn} />
        <Route path={AuthRoutes.EmailConfirmed} component={EmailConfirmed} />
        <Route path={AuthRoutes.PasswordChange} component={PasswordChange} />
        <Route path={AuthRoutes.ForgotPassword} component={ForgotPassword} />

        <Redirect to='/' />
      </Switch>

      <Footer isTransparent />
    </AuthWrapper>
  );
};

export default Auth;
