import React from 'react';
// routes
import { Link } from 'react-router-dom';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const Header: React.FC = () => {
  return (
    <header className={cn('header')}>
      <nav className={cn('header-navbar')}>
        <Link to='/' className={cn('header-logo')}>
          The Giftbuster
        </Link>
      </nav>
    </header>
  );
};

export default Header;
