export const playMarket = 'https://play.google.com/store/apps/details?id=com.giftbuster';
export const appStore = 'https://itunes.apple.com/us/app/giftbuster-wish-list-registry/id1051348033';
export const facebook = 'https://www.facebook.com/giftbusterapp';
export const twitter = 'https://twitter.com/GiftBuster';
export const pinterest = 'https://pinterest.com/giftbuster/';
export const instagram = 'http://instagram.com/giftbuster_app/';
export const blogPost = 'http://blog.thegiftbuster.com';
export const mailAddress = 'info@giftbuster.app';
export const blog = 'http://blog.thegiftbuster.com/';
export const mailMarketing = 'marketing@giftbuster.app';
