import React, { useMemo, useCallback } from 'react';
// components
import SocialSignIn from './Social';
import Form from './components/Form';
import FormWrapper from '../components/FormWrapper';
// hooks
import { queryCache } from 'react-query';
// redux
import useTDispatch from 'hooks/redux/useTDispatch';
import { updateAuth } from 'store/modules/auth';
// routes
import { useHistory } from 'react-router-dom';
// services
import api from 'services/api';
import { updateAuthData as updateAuthDataInStorage } from 'services/storage/auth';
// types
import { SignInFormOnSubmit, SignInFormValuesType } from './components/Form/types';

const SingIn: React.FC = () => {
  const dispatch = useTDispatch();
  const history = useHistory();

  const initialValues = useMemo(
    (): SignInFormValuesType => ({
      email: '',
      password: '',
      platformName: 'web',
    }),
    [],
  );

  const onSignIn: SignInFormOnSubmit = useCallback(
    async (values, { setSubmitting, setErrors }) => {
      try {
        const authData = await api.auth.signIn(values);

        updateAuthDataInStorage(authData);

        const user = await api.auth.getProfile();
        if (user) {
          dispatch(updateAuth(user));
          setSubmitting(false);

          queryCache.clear();
          history.push(`/user/${user.id}`);
        }
      } catch ({ data }) {
        if (data.errors) {
          setErrors(data.errors);
        }

        setSubmitting(false);
      }
    },
    [dispatch, history],
  );

  return (
    <FormWrapper>
      <Form initialValues={initialValues} onSubmit={onSignIn} />

      <SocialSignIn />
    </FormWrapper>
  );
};

export default SingIn;
