import React, { FunctionComponent, memo, useCallback, useState, useEffect } from 'react';
// components
import Loader from 'components/Loader';
// router
import { Switch, Route, Redirect } from 'react-router-dom';
// navigation
import Profile from 'navigation/Profile';
import Static from 'navigation/Static';
import Auth from 'navigation/Auth';
import { StaticRoutes, ProfileRoutes, AuthRoutes } from 'navigation/Routes';
// redux
import useTSelector from 'hooks/redux/useTSelector';
import useTDispatch from 'hooks/redux/useTDispatch';
// actions
import { updateAuth } from 'store/modules/auth';
import { logOut } from 'store/modules/auth/effects';
// pages
import LandingPage from 'pages/LandingPage';
// services
import { getAuthData } from 'services/storage/auth';
import api from 'services/api';

const AppNavigation: FunctionComponent = () => {
  const user = useTSelector((state) => state.auth.user);
  const dispatch = useTDispatch();

  const [isAuthLoading, setAuthLoading] = useState(true);

  const bootstrapAsync = useCallback(async () => {
    const authToken = await getAuthData();

    if (authToken) {
      try {
        const userProfile = await api.auth.getProfile();

        if (userProfile) {
          dispatch(updateAuth(userProfile));
          setAuthLoading(false);

          return;
        }
      } catch (e) {
        console.error(e);
      }
    }

    // some problems with profile loading, stored session data or user authorization has been expired
    await dispatch(logOut());
    setAuthLoading(false);
  }, [dispatch]);

  useEffect(() => {
    bootstrapAsync();
  }, [bootstrapAsync]);

  return (
    <>
      {isAuthLoading ? (
        <div className='app-loader'>
          <Loader />
        </div>
      ) : (
        <Switch>
          <Route exact path='/' component={LandingPage} />

          <Route
            path={[
              ProfileRoutes.Gift,
              ProfileRoutes.User,
              ProfileRoutes.WishList,
              ProfileRoutes.Followers,
              ProfileRoutes.Following,
            ]}
            component={Profile}
          />
          <Route path={[StaticRoutes.FAQ, StaticRoutes.Privacy, StaticRoutes.Terms]} component={Static} />

          {user ? (
            <Redirect to={`user/${user.id}`} />
          ) : (
            <Route
              path={[
                AuthRoutes.EmailConfirmed,
                AuthRoutes.PasswordChange,
                AuthRoutes.SignIn,
                AuthRoutes.ForgotPassword,
              ]}
              component={Auth}
            />
          )}

          <Redirect to='/' />
        </Switch>
      )}
    </>
  );
};

export default memo(AppNavigation);
