import React from 'react';
// components
import StoreButton from 'components/StoreButton';
// localization
import { useTranslation } from 'react-i18next';
// icons
import logoIcon from 'assets/icons/logo.svg';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const FormWrapper: React.FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className={cn('container')}>
      <div className={cn('form-wrapper')}>
        <span className={cn('form-wrapper-icon')}>
          <img src={logoIcon} alt='' />
        </span>

        {children}
      </div>

      <div className={cn('account')}>
        <p className={cn('account-title')}>{t('landing:account')}</p>
        <p className={cn('account-description')}>{t('landing:downloadApp')}</p>

        <div className={cn('account-market')}>
          <StoreButton store='google' />
          <StoreButton store='apple' />
        </div>
      </div>
    </div>
  );
};

export default FormWrapper;
