import React, { useCallback, useMemo } from 'react';
// components
import WishlistCard from 'components/WishlistCard';
import Loader from 'components/Loader';
import InfiniteScroll from 'react-infinite-scroller';
import NoDataBar from 'components/NoDataBar';
// routes
import { useHistory, useParams } from 'react-router-dom';
// entities
import { Wishlist } from 'entities/wishlist';
// hooks
import { useInfiniteQuery } from 'react-query';
// services
import api from 'services/api';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const ListsTabPage: React.FC = () => {
  const history = useHistory();

  // TODO: implement ts for navigation
  const { userId } = useParams<{ userId?: string; wishlistId?: string; giftId?: string }>();

  const { data: paginatedWishlists, isLoading, fetchMore, canFetchMore } = useInfiniteQuery(
    ['wishlists', userId],
    (_key: string, id: number, page: number | undefined = 1) =>
      api.wishlist.getList(
        RequestQueryBuilder.create({
          limit: 12,
          page,
          search: {
            '"Wishlist"."userId"': id,
          },
          sort: [{ field: 'createdAt', order: 'DESC' }],
        }).query(),
      ),
    {
      getFetchMore: (lastGroup) => (lastGroup.page < lastGroup.pageCount ? lastGroup.page + 1 : undefined),
      // 5 min
      staleTime: 1000 * 60 * 5,
    },
  );

  const wishlists = useMemo(
    () =>
      paginatedWishlists
        ?.reduce((res, page) => [...res, ...page.data], [] as Wishlist[])
        .filter((item) => (!item.lastGifts ? null : item)),
    [paginatedWishlists],
  );

  const onWishlistCardClick = useCallback(
    (id) => history.push({ pathname: `${history.location.pathname}/wishlist/${id}` }),
    [history],
  );

  return (
    <div className={cn('wishlist')}>
      {isLoading ? (
        <Loader />
      ) : wishlists && (wishlists.length ?? 0) > 0 ? (
        <InfiniteScroll
          loadMore={() => canFetchMore && fetchMore()}
          hasMore={canFetchMore}
          loader={<Loader />}
          className={cn('wishlist-gallery')}
        >
          {wishlists.map((wishlist) => (
            <WishlistCard key={wishlist.id} wishlist={wishlist} onClick={() => onWishlistCardClick(wishlist.id)} />
          ))}
        </InfiniteScroll>
      ) : (
        <NoDataBar />
      )}
    </div>
  );
};

export default ListsTabPage;
