import React from 'react';
// components
import StoreButton from 'components/StoreButton';
// localization
import { useTranslation } from 'react-i18next';
//  images
import Logo from 'assets/icons/logo.svg';
// styles
import styles from './styles.module.scss';

const GetStart: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <span>
        <img src={Logo} alt='' className={styles.logo} />
      </span>
      <h2 className={styles.title}>{t('landing:getStart:titleOne')}</h2>
      <span className={styles.description}>{t('landing:getStart:descriptionOne')}</span>

      <div className={styles.buttonContainer}>
        <StoreButton store='google' />
        <StoreButton store='apple' />
      </div>
    </div>
  );
};

export default GetStart;
