import React, { FunctionComponent, memo } from 'react';
// styles
import styles from './styles.module.scss';

const YouTube: FunctionComponent = () => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <iframe
        title='Promo'
        frameBorder='0'
        allowFullScreen
        src='https://www.youtube.com/embed/oKQ53dwczYI'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          height: '100%',
          margin: '0 auto',
        }}
      />
    </div>
  </div>
);

export default memo(YouTube);
