// localization
import { TFunction } from 'i18next';
// utils
import * as Yup from 'yup';

const getValidationSchema = (t: TFunction) =>
  Yup.object({
    email: Yup.string().email(t('forms:email')).required(t('forms:required')),
    password: Yup.string()
      .min(
        6,
        t('forms:minNumberValue', {
          min: 6,
        }),
      )
      .max(
        64,
        t('forms:maxNumberValue', {
          max: 64,
        }),
      )
      .required(t('forms:required')),
    platformName: Yup.string().defined(),
  }).defined();

export default getValidationSchema;
