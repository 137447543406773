import React, { useCallback } from 'react';
// components
import Button from 'components/Button';
// routes
import { useHistory } from 'react-router-dom';
// hooks
import { queryCache } from 'react-query';
// services
import api from 'services/api';
import { updateAuthData as updateAuthDataInStorage } from 'services/storage/auth';
// redux
import useTDispatch from 'hooks/redux/useTDispatch';
import { updateAuth } from 'store/modules/auth';
// types
import { SocialType } from '../../../../entities/dictionaries';
// icons
import appleIcon from 'assets/icons/apple-circle.svg';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const Apple: React.FC = () => {
  const dispatch = useTDispatch();
  const history = useHistory();

  const onSignIn = useCallback(async () => {
    try {
      window.AppleID.auth.init({
        clientId: 'com.giftbuster.web',
        scope: 'email name',
        redirectURI: 'https://giftbuster.app/',
        state: '',
        usePopup: true,
      });
      const authResponse = await window.AppleID.auth.signIn();

      if (authResponse) {
        const userFullName = authResponse.user ? authResponse.user.name : {};
        const authData = await api.auth.socialSignIn({
          ...userFullName,
          tokens: {
            token1: authResponse.authorization.id_token,
          },
          socialType: SocialType.Apple,
          platformName: 'web',
        });

        updateAuthDataInStorage(authData);

        const user = await api.auth.getProfile();

        if (user) {
          dispatch(updateAuth(user));
          queryCache.clear();
          history.push(`/user/${user.id}`);
        } else {
          throw new Error('Something went wrong with getting user');
        }
      } else {
        throw new Error('Something went wrong obtaining the users access token');
      }
    } catch (error) {
      // TODO: improve error flow
      console.error(error);
    }
  }, [history, dispatch]);

  return (
    <Button mode='text' onClick={onSignIn} className={cn('social-button')}>
      <img src={appleIcon} alt='Apple' />
    </Button>
  );
};

export default Apple;
