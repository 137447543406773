import React from 'react';
// localization
import { useTranslation } from 'react-i18next';
// styles
import styles from './styles.module.scss';
// utils
import { blog } from 'utils/links';

const BlogPost = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.blog}>
        <p className={styles.title}>{t('landing:blog:title')}</p>
        <p className={styles.description}>{t('landing:blog:description')}</p>
        <a href={blog} className={styles.button}>
          {t('landing:blog:readMore')}
        </a>
      </div>
    </div>
  );
};

export default BlogPost;
