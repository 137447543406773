import React, { useCallback, useEffect, useState } from 'react';
import formatDistance from 'date-fns/formatDistance';
// localization
import { dateLocales } from '../../services/localization/i18n';
import { useTranslation } from 'react-i18next';

type TimeAgoProps = React.HTMLProps<HTMLSpanElement> & {
  dateTime: Date | string;
};

const TimeAgo: React.FC<TimeAgoProps> = ({ dateTime, ...props }) => {
  const { i18n } = useTranslation();
  const [timeAgo, setTimeAgo] = useState('');

  const calculateTimeAgo = useCallback(() => {
    setTimeAgo(
      formatDistance(typeof dateTime === 'string' ? new Date(dateTime) : dateTime, new Date(), {
        addSuffix: false,
        locale: dateLocales[i18n.language],
      }),
    );
  }, [dateTime, i18n.language]);

  useEffect(() => {
    calculateTimeAgo();
    const interval = setInterval(calculateTimeAgo, 60000);

    return () => clearInterval(interval);
  }, [calculateTimeAgo]);

  return <span {...props}>{timeAgo}</span>;
};

export default TimeAgo;
