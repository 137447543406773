import React, { useCallback, useMemo, useEffect } from 'react';
// components
import GiftCard from 'components/GiftCard';
import Loader from 'components/Loader';
import Breadcrumb, { BreadcrumbType } from 'components/Breadcrumb';
import InfiniteScroll from 'react-infinite-scroller';
import NoDataBar from 'components/NoDataBar';
// entities
import { Gift } from 'entities/gift';
// routes
import { useHistory, useParams } from 'react-router-dom';
import { CommonRoutes } from 'navigation/Routes';
// hooks
import { useInfiniteQuery } from 'react-query';
// services
import api from 'services/api';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const WishlistPage: React.FC = () => {
  const history = useHistory();

  const { wishlistId, userId } = useParams<{ userId?: string; wishlistId?: string; giftId?: string }>();

  const { data: paginatedGifts, isLoading, fetchMore, canFetchMore } = useInfiniteQuery(
    ['gifts', wishlistId],
    (_key: string, id: number, page: number | undefined = 1) =>
      api.gift.getList(
        RequestQueryBuilder.create({
          limit: 16,
          page,
          search: {
            $and: [{ '"Gift"."wishlistId"': id }],
          },
          sort: [{ field: 'createdAt', order: 'DESC' }],
        }).query(),
      ),
    {
      getFetchMore: (lastGroup) => (lastGroup.page < lastGroup.pageCount ? lastGroup.page + 1 : undefined),
    },
  );

  const gifts = useMemo(() => paginatedGifts?.reduce((res, page) => [...res, ...page.data], [] as Gift[]), [
    paginatedGifts,
  ]);

  const onGiftCardClick = useCallback((id) => history.push({ pathname: `${history.location.pathname}/gift/${id}` }), [
    history,
  ]);

  const breadcrumbs: BreadcrumbType[] = useMemo(() => {
    const userLink = `${CommonRoutes.user}/${gifts?.[0]?.user?.id || userId}`;

    return [{ title: 'Wish Lists', link: userLink }, { title: gifts?.[0]?.wishlist?.name }];
  }, [gifts, userId]);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div className={cn('wishlist-wrapper')}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Breadcrumb data={breadcrumbs} />
          {gifts && (gifts.length ?? 0) > 0 ? (
            <InfiniteScroll
              loadMore={() => canFetchMore && fetchMore()}
              hasMore={canFetchMore}
              loader={<Loader />}
              className={cn('wishlist')}
            >
              {gifts.map((gift) => (
                <GiftCard
                  key={gift.id}
                  gift={gift}
                  className={cn('wishlist__card')}
                  onPhotoClick={() => onGiftCardClick(gift.id)}
                />
              ))}
            </InfiniteScroll>
          ) : (
            <NoDataBar />
          )}
        </>
      )}
    </div>
  );
};

export default WishlistPage;
