import React, { FunctionComponent, memo, useMemo } from 'react';
// localization
import { useTranslation } from 'react-i18next';
// images
import facebookIcon from 'assets/icons/facebook.svg';
import twitterIcon from 'assets/icons/twitter.svg';
import pinterestIcon from 'assets/icons/pinterest.svg';
import instagramIcon from 'assets/icons/instagram.svg';
// utils
import { facebook, twitter, pinterest, instagram, mailAddress, mailMarketing } from 'utils/links';
// styles
import styles from './Footer.module.scss';
import classNames from 'classnames/bind';

type Props = {
  isTransparent?: boolean;
};

const cn = classNames.bind(styles);

const Footer: FunctionComponent<Props> = ({ isTransparent }) => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const { t } = useTranslation();

  return (
    <footer className={cn('container', isTransparent && 'transparent')}>
      <span className={styles.year}>{`© ${currentYear} ${t('common:rights')}`}</span>

      <nav className={styles.navigation}>
        <ul className={styles.navigation}>
          <li className={styles.navItem}>
            <a href={`mailto:${mailMarketing}`}>{t('common:workWithUs')}</a>
          </li>
          <li className={styles.navItem}>
            <a href='/privacy'>{t('common:privacy')}</a>
          </li>
          <li className={styles.navItem}>
            <a href='/terms'>{t('common:seeTerms')}</a>
          </li>
          <li className={styles.navItem}>
            <a href='/faq'>{t('common:faq')}</a>
          </li>
          <li className={styles.navItem}>
            <a href={`mailto:${mailAddress}`}>{t('common:contact')}</a>
          </li>
        </ul>
      </nav>

      <div className={styles.iconsContainer}>
        <a href={facebook} rel='noopener noreferrer' target='_blank'>
          <img src={facebookIcon} alt='facebook' className={styles.icon} />
        </a>

        <a href={twitter} rel='noopener noreferrer' target='_blank'>
          <img src={twitterIcon} alt='twitter' className={styles.icon} />
        </a>

        <a href={pinterest} rel='noopener noreferrer' target='_blank'>
          <img src={pinterestIcon} alt='facebook' className={styles.icon} />
        </a>

        <a href={instagram} rel='noopener noreferrer' target='_blank'>
          <img src={instagramIcon} alt='twitter' className={styles.icon} />
        </a>
      </div>
    </footer>
  );
};

export default memo(Footer);
