import React, { useCallback } from 'react';
// components
import Button from 'components/Button';
// routes
import { useHistory } from 'react-router-dom';
// hooks
import { queryCache } from 'react-query';
// services
import api from 'services/api';
import { updateAuthData as updateAuthDataInStorage } from 'services/storage/auth';
// redux
import useTDispatch from 'hooks/redux/useTDispatch';
import { updateAuth } from 'store/modules/auth';
// types
import { SocialType } from '../../../../entities/dictionaries';
// icons
import facebookIcon from 'assets/icons/facebook-circle.svg';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const Facebook: React.FC = () => {
  const dispatch = useTDispatch();
  const history = useHistory();

  const onSignIn = useCallback(async () => {
    try {
      const authResponse: fb.AuthResponse = await new Promise((resolve, reject) => {
        window.FB.login(
          (response: fb.StatusResponse) => {
            if (response.authResponse) {
              resolve(response.authResponse);
            } else {
              reject(new Error('User cancelled request'));
            }
          },
          { scope: 'public_profile,email,user_birthday,user_gender' },
        );
      });

      if (authResponse) {
        const authData = await api.auth.socialSignIn({
          tokens: {
            token1: authResponse.accessToken,
          },
          socialType: SocialType.Facebook,
          platformName: 'web',
        });

        updateAuthDataInStorage(authData);

        const user = await api.auth.getProfile();

        if (user) {
          dispatch(updateAuth(user));
          queryCache.clear();
          history.push(`/user/${user.id}`);
        } else {
          throw new Error('Something went wrong with getting user');
        }
      } else {
        throw new Error('Something went wrong obtaining the users access token');
      }
    } catch (error) {
      // TODO: improve error flow
      console.error(error);
    }
  }, [history, dispatch]);

  return (
    <Button mode='text' onClick={onSignIn} className={cn('social-button')}>
      <img src={facebookIcon} alt='Facebook' />
    </Button>
  );
};

export default Facebook;
