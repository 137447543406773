import React from 'react';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const AuthWrapper: React.FC = ({ children }) => {
  return (
    <div className={cn('container')}>
      <div className={cn('mainBackground')}>{children}</div>
    </div>
  );
};

export default AuthWrapper;
