import auth from './endpoints/auth';
import user from './endpoints/user';
import wishlist from './endpoints/wishlist';
import gift from './endpoints/gift';
import follow from './endpoints/follow';

export default {
  auth,
  user,
  wishlist,
  gift,
  follow,
};
