import React from 'react';
// localization
import { useTranslation } from 'react-i18next';
// icons
import noDataIcon from 'assets/icons/card-bulleted-settings-outline.svg';
// styles
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

type Props = {
  title?: string;
  description?: string;
  icon?: string;
};

const NoDataBar: React.FC<Props> = ({ title, description, icon }) => {
  const { t } = useTranslation();

  return (
    <div className={cn('no-data')}>
      <img src={icon ? icon : noDataIcon} className={cn('no-data__image')} alt='' />

      <p className={cn('no-data__title')}>{title ? title : t('common:noDataFound')}</p>
      <p className={cn('no-data__description')}>{description ? description : t('common:noDataFoundDescription')}</p>
    </div>
  );
};

export default NoDataBar;
