import React from 'react';
// localization
import { useTranslation } from 'react-i18next';
// images
import Image from 'assets/images/collaborate.jpg';
// styles
import styles from './styles.module.scss';
// utils
import { mailMarketing } from 'utils/links';

const Collaborate = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{t('landing:collaborate:title')}</p>
      <div className={styles.content}>
        <p className={styles.label}>{t('landing:collaborate:label')}</p>
        <p className={styles.description}>
          {t('landing:collaborate:description')}{' '}
          <a href={`mailto:${mailMarketing}`} className={styles.link}>
            {t('common:contact')}
          </a>
        </p>
      </div>
      <span className={styles.image}>
        <img src={Image} alt='' />
      </span>
    </div>
  );
};

export default Collaborate;
